.simo-links{
    font-family: RobotoLight,sans-serif;
    font-size: 20px;
}

.simo-links-headline{
    font-family: RobotoLight,sans-serif;
    color: #dc0014;
    font-size: 28px;
    padding-bottom: 20px;

}

.simo-linkingpage{
    padding-top: 50px;
    padding-left: 100px;
}