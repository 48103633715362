
/* ----------------------------------------- Teaser ----------------------------------------- */

.kontakt-teaser{
    padding: 15px;
}
.kontakt-teaser .teaser-content{
    z-index: 1;
}
.kontakt-teaser:after{
    content: "\E01D";
    font-family: iconfont;
    position: absolute;
    right: 22px;
    bottom: 14px;
    font-size: 68px;
    color: rgba(0,0,0, .2);
    z-index: 0;
}

.content-teaser-border{
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
}

.teaser-img{
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.content-teaser-text{
    padding-right: 15px;
    padding-top: 15px;
}

.offer-teaser-border{
    overflow: hidden;
    position: relative;

    padding: 4px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.offer-teaser-text{
    padding-right: 15px;
    padding-top: 15px;
}

.offer-teaser-border h2,
.content-teaser-border h2{
    padding-top: 15px;
}

.offer-teaser-ribbon{

    content: '';
    height: 50px;
    position: absolute;
    z-index: 2;
    margin-top: 30px;
    left: 0;

    -webkit-backface-visibility: hidden;
    transform: skewX(-23deg);
}

.offer-teaser-ribbon-xs{

    content: '';
    text-align: center;
    height: 30px;

}

.offer-teaser-ribbon-xs span{
    display: block;
    font-family: RobotoBold, sans-serif;
    color: #fff;
    font-size: 18px;
    padding-top: 6px;
}

.offer-teaser-ribbon span{
    display: block;
    font-family: RobotoBold, sans-serif;
    color: #fff;
    font-size: 18px;

    margin-left: 25px;
    margin-top: 13px;
    padding-right: 15px;

    -webkit-backface-visibility: hidden;
    transform: skewX(23deg);
}

.full-ribbon{
    display: none;
}

.content-block .teaser-content p  {
    font-size: 16px;
    font-family: RobotoLight, sans-serif;
    font-weight: 300;
}

.read-more {
    text-transform: uppercase;
    cursor: pointer;
    list-style: none;
    font-family: Oswald-Regular, sans-serif;
    font-size: 15px;
    position: relative;
    margin-left: 12px;
}

.read-more i{
    position: absolute;
    font-size: 10px;
    top: 6px;
    left: -12px;
}

.read-more:hover{;
    text-decoration: none;
    /*color: #ff0017;*/
}

.read-more a:hover{;
    text-decoration: none;
    /*color: #ff0017;*/
}

/*.read-more:hover .icon-arrow {*/
    /*left: -10px;*/
    /*transition: all .1s linear;*/
/*}*/
.content-teaser-border,
.offer-teaser .offer-teaser-border {
    transform: translateY(0);
}

.content-teaser-border:hover,
.offer-teaser .offer-teaser-border:hover{
    box-shadow: 0 3px 4px rgba(0,0,0,.15);
    transform: translateY(-2px);
}

.content-teaser h2,
.offer-teaser h2{
    margin-top: 0;
    padding-top: 20px;
}

.content-teaser h2 a:hover,
.offer-teaser h2 a:hover{
    text-decoration: none;
}


/* ------------ Event Teaser ------------- */

.event-teaser h2 a:hover,
.event-teaser-monthly h2 a:hover{
    text-decoration: none;
}

.event-teaser{
    padding-bottom: 30px;
    margin-top: 24px;
}

.event-teaser .offer-teaser-border{
    overflow: visible;
    border: none;
    padding: 0;
    padding-bottom: 25px;

    border-bottom: 1px solid #ddd;
}

.event-teaser .offer-teaser-border .teaser-img{
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.event-teaser-monthly{
    margin-top: 30px;
}
.event-teaser .offer-teaser-ribbon,
.event-teaser-monthly .offer-teaser-ribbon{
    height: 70px;
    width: 70px;

    text-align: center;

    margin-top: 20px;
    /*margin-left: 11px;*/
    -webkit-backface-visibility: hidden;
    transform: skewX(0deg);
}

/*.event-teaser-monthly .offer-teaser-ribbon{*/
    /*margin-left: 26px;*/
/*}*/

.event-teaser-monthly .content-teaser-border h2{
    padding-top: 0;
}

.teaser-month{
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;

    margin-top: -6px;
}

.teaser-day{
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoLight, sans-serif;
    font-size: 32px;

    margin-top: 3px;
}

.event-teaser .offer-teaser-border h2{
    padding-top: 0;
    margin-top: 0;
}

.teaser-category{
    text-transform: uppercase;

    color: #666;
    font-family: RobotoLight, sans-serif;

    font-size: 16px;

    margin-top: -10px;
    padding-bottom: 10px;
    position: relative;
    padding-left: 25px;

}

.event-teaser-monthly .content-teaser-border .teaser-img{
    padding: 0;
    margin: 0;
}

.event-teaser-monthly .teaser-content{
    height: 225px;
    margin-left: 15px;
}

.event-teaser-monthly .content-teaser h2{
    margin-bottom: 3px;
    padding-top: 15px;
}

.event-teaser-monthly .read-more{
    position: absolute;
    bottom: 38px;
}


/* ----------- Erlebniss Teaser --------- */

.teaser-experience-ribbon{

    content: '';
    height: 50px;
    position: absolute;
    z-index: 2;
    bottom: 25px;
    right: -10px;

    -webkit-backface-visibility: hidden;
    transform: skewX(-23deg);
}

.experience-teaser .offer-teaser-border{
    overflow: hidden;
}

.experience-teaser .offer-teaser-border:hover,
.event-teaser .offer-teaser-border:hover{
    box-shadow: 0 0 5px rgba(0,0,0,.2);
}

.teaser-experience-ribbon span{
    display: block;
    font-family: RobotoBold, sans-serif;
    color: #fff;
    font-size: 18px;

    margin-left: 25px;
    margin-top: 13px;
    padding-right: 20px;

    -webkit-backface-visibility: hidden;
    transform: skewX(23deg);
}

/* --------------- Restaurants --------------------- */


.restaurant-teaser .offer-teaser-ribbon{
    -webkit-backface-visibility: hidden;
    transform: skewX(-23deg);
    width: 60px;
    height: 50px;

    margin-left: 0px;
    margin-top: 0;
}

.restaurant-teaser  .offer-teaser-ribbon span{
    margin-left: 22px;
    margin-top: 11px;
}

.restaurant-teaser .read-more{
    top: 11px;
}




/******************* ----------- MEDIA QUERIES ------------ ********************/

/* ----------------------- 991px - 1199px ------------z.B. iPad----------- */
@media (min-width: 991px) and (max-width: 1199px) {

    .event-teaser-monthly .content-teaser h2 {
        padding-top: 6px;
    }

    .event-teaser-monthly .content-block h2{
        font-size: 20px;
    }

    .event-teaser-monthly .content-block .teaser-content p{
        font-size: 14px;
    }
}


@media (min-width: 768px) and (max-width: 992px) {
    .content-teaser h2, .offer-teaser h2 {
        margin-top: 0;
        padding-top: 10px;
    }

    .content-block .teaser-content p {
        font-size: 15px;
    }

    .read-more {
        font-size: 14px;
    }

    .offer-teaser-ribbon{
        height: 40px;
        margin-top: 20px;
    }

    .offer-teaser-ribbon span{
        font-size: 14px;
        margin-top: 12px;
    }

    .content-teaser-border .teaser-img {
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .offer-teaser-border .teaser-img {
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

/* ----------------------- 0 - 768px ----------------------- */

@media (max-width: 768px) {

    .content-block .teaser-content p {
        font-size: 14px;
    }

    .teaser-content{
        padding-right: 25px;
        padding-left: 25px;
    }

    .content-teaser h2, .offer-teaser h2 {
        padding-top: 5px;
        margin-bottom: 5px;
    }

    .content-teaser-border .teaser-img {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 9px;
    }

    .offer-teaser-border .teaser-img {
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .offer-teaser-border {
        overflow: visible;
    }

    .offer-teaser-ribbon {

        content: '';

        height: 30px;

        left: 0;
        margin-top: 0;
        padding-bottom: 20px;

        -webkit-backface-visibility: hidden;
        transform: skewX(0deg);
    }

    .offer-teaser-ribbon span {
        font-size: 12px;
        margin-top: 6px;
        margin-left: 10px;

        -webkit-backface-visibility: hidden;
        transform: skewX(0deg);
    }

    .offer-teaser-ribbon-xs span {
        padding-top: 2px;
    }

    .content-teaser h2 a, .offer-teaser h2 a{
        font-size: 18px;
    }

    .read-more {
        font-size: 14px;
        top: -5px;
    }

    .content-block .teaser-content p  {
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }


    /* Event */

    .event-teaser .offer-teaser-border h2 {
        padding-top: 10px;
    }

    .event-teaser .offer-teaser-border {
        padding-bottom: 5px;
    }

    .event-teaser .teaser-content{
        padding-left: 12px;
        padding-right: 12px;
    }

    .event-teaser-monthly .read-more {
        position: relative;
        font-size: 13px;
    }

    .event-teaser-monthly .teaser-content {
        height: auto;
        margin-left: 0;
    }

    /* Restaurants */

    .restaurant-teaser .offer-teaser-ribbon {
        margin-left: -33px;
    }

    .restaurant-teaser .offer-teaser-ribbon span {
        -webkit-backface-visibility: hidden;
        transform: skewX(23deg);
        font-size: 18px;
    }

    .teaser-experience-ribbon span {
        font-size: 12px;
        margin-left: 13px;
        margin-top: 9px;
        padding-right: 20px;
    }


    .teaser-experience-ribbon {
        height: 35px;
        bottom: 0;
    }

    .restaurant-teaser .offer-teaser-border {
        padding-bottom: 15px;
    }


}


/* ----------------------- 0 - 514px ----------------------- */

@media (max-width: 514px) {

    .event-teaser-monthly .content-block .teaser-content p {
        overflow: visible;
        text-overflow: clip;
        -o-text-overflow: clip;
        white-space: normal;
        width: 100%;

        font-size: 14px;
    }

}

/* ----------------------- 0 - 340px ----------------------- */

@media (max-width: 340px) {

    .event-teaser-monthly .content-teaser-border .teaser-img{
        width: auto;
    }
}