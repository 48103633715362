.search-suggestions {
    margin-top: 30px;
}
.search-suggestions__title {
    color: #0064aa;
}
.search-suggestions__list a {
    text-decoration: underline;
    color: inherit;
}
.search-suggestions__list a:hover {
    text-decoration: underline;
    color: #dc0014;
}