.snap-drawer-right {
    background-color: #f2f2f2;
}
.snap-drawer-right .nav {
    padding-top: 0px;
}
.snap-drawer-right li{
    border-bottom: 1px solid #d9d9d9;
}

.snap-drawer-right li > .icon{
    color: #999;
    font-size: 12px;
    position: absolute;
    transform: rotate(45deg);
    right: 0px;
    padding: 10px;
    top: 8px;
    transition: transform 300ms;
}

.snap-drawer-right li.open > .icon {
    transform: rotate(0deg);
}

.snap-drawer-right ul{
    padding: 0 15px;
}

.snap-drawer-right li a{
    font-size: 20px;
    color: #004678;
    padding: 10px 8px;
}

.snap-drawer-right #site-nav > li.active > a{
    color: #004678;
}

.snap-drawer-right .dropdown-navigation li a{
    font-size: 18px;
    color: #dc0014;
    display: block;
    padding: 0;
}

.snap-drawer-right .dropdown-navigation li .dropdown-navigation li a{
    color: #000;
}

.snap-drawer-right .dropdown-navigation ul{
    list-style: none;
    padding-left: 11px;
    padding-right: 0;
}

.snap-drawer-right .dropdown-navigation li{
    padding: 10px 0;
    position: relative;
}

.snap-drawer-right .dropdown-navigation li:first-child{
    border-top: 1px solid #d9d9d9;
}

.snap-drawer-right .dropdown-navigation li:last-child{
   border: none;
}

.mobile-nav-buttons {
    padding: 0 15px;
    margin-top: 15px;
}
.mobile-nav-buttons__btn {
    border-radius: 0;
    background: #333333;
    color: #d9d9d9;
    padding: 9px 15px;
}
.mobile-nav-buttons__btn:hover,
.mobile-nav-buttons__btn:active,
.mobile-nav-buttons__btn:focus {
    color: #d9d9d9;
}
.mobile-nav-buttons__btn .icon {
    font-size: 18px;
    vertical-align: -.2em;
    margin-right: 4px;
}
.navbar-mobile {
    margin: 30px 15px 45px;
}
.toggle-account-nav {
    border-bottom: 2px solid #dc0014;
    display: none;
}

@media screen and (min-width: 768px){
    .toggle-account-nav{
        display: none;
    }
}
.toggle-account-nav li {
    border-bottom: 1px solid #d9d9d9;
}

.nav-custom .toggle-account-nav .navbar-nav>li>a{
    font-size: 20px;
    color: #004678;
    padding: 10px 8px;
    text-transform: uppercase;
    background-color: transparent;
}

.nav-custom .toggle-account-nav .navbar-nav>li>a:focus,
.nav-custom .toggle-account-nav .navbar-nav>li>a:hover,
.nav-custom .toggle-account-nav .navbar-nav>li.active>a,
.snap-drawer-right #site-nav > li.active > a,
.snap-drawer-right #site-nav li a:focus, .snap-drawer-right #site-nav li a:hover{
    background-color: #004678;
    color: #fff;
}

.snap-drawer-right #site-nav > li.active > .icon,
.snap-drawer-right .dropdown-navigation li.active a {
    color: #fff;
}

.snap-drawer-right #site-nav .dropdown-navigation li:focus,
.snap-drawer-right #site-nav .dropdown-navigation li:hover,
.snap-drawer-right #site-nav .dropdown-navigation li.active,
.snap-drawer-right #site-nav .dropdown-navigation li:focus a,
.snap-drawer-right #site-nav .dropdown-navigation li:hover a,
.snap-drawer-right #site-nav .dropdown-navigation li.active a,
.snap-drawer-right #site-nav .dropdown-navigation > li.active > a {
    background-color: #dc0014;
    text-decoration: none;
}