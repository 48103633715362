.border-left-f2f2f2{
	border-left: 1px solid #F2F2F2;
}
.border-right-f2f2f2{
	border-right: 1px solid #F2F2F2;
}

.checkbox-layer{
	cursor: pointer;
}

.maps-info-window .headline{
	font-size: 20px;
	font-family: RobotoCondensedBold, sans-serif;
}

.detail-map{
	z-index: 2;
}
.map-placeholder{
	position: relative;
}
.map-marker-label{
	position: absolute;
	color: #fff;
	font-size: 20px;
	text-align: center;
}
.map-marker-label div{
	padding-top: 5px;
	display: inline-block;
	margin-left: -8px;
}
.map-controls{
	color: #999999;
	background-color: #fff;
	position: absolute;
	width: 100px;
	height: 50px;
	right: 45px;
	bottom: 40px;
	z-index: 10;
	cursor: pointer;
	-webkit-box-shadow: 0 10px 30px -10px rgba(0,0,0,.5);
	box-shadow: 0 10px 30px -10px rgba(0,0,0,.5);


	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}
.map-controls div{
	color: #999999;
	width: 50px;
	height: 50px;
	border-radius: 2px;
	text-align: center;
	display: block;
	position: absolute;
	margin: 0;
	padding: 0;
	font-size: 16px;
	left: 0;
	top: 0;
	overflow: hidden;

	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}
.map-controls div circle{
	fill: #fff;
}
.map-controls div:hover{
	background: #efefef;
	color: #000;
}
.map-controls div i{
	position: absolute;
	top: 16px;
	left: 16px;
}
.map-controls div:last-child{
	border-left: 0;
}
.map-controls div.zoom-out{
	border-left: 1px solid #ccc;
	font-size: 2px;
	right: 0;
	top: 0;
	left: auto;
}
.map-controls div.zoom-out i{
	top: 23px;
	left: 14px;
}


.pr-bild{
	cursor: pointer;
}

.pr-bild a:hover{
	text-decoration: none;
}

#lightbox{
}
#lightbox .lb-image{

}
#lightbox .lb-container{
	padding: 20px;
	display: inline-block;
	margin: 0 auto;
	background: #fff;
}



figure {
	position: relative;
	overflow: hidden;
	/*background: #3085a3;*/
	text-align: center;
	cursor: pointer;
}

figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	/*opacity: 0.8;*/
}

.single-image figure{
	cursor: auto;
}

figure figcaption h2{
	margin-top: 0;
}
figure figcaption {
	padding: 2em;
	color: #fff;

	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure figcaption,
figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.presse-texte-teaser figure{
    max-height: 167px;
}
.presse-texte-teaser.content-block{
	margin-bottom: 0;
}
.presse-fancy-bottom.content-teaser-border{
	margin-bottom: 40px;
}

/*-----------------*/
/***** Selena *****/
/*-----------------*/

figure.effect-selena {
	background: #fff;
}

figure.effect-selena img {
	opacity: 0.95;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

figure.effect-selena:hover img {
	-webkit-transform: scale3d(0.95,0.95,1);
	transform: scale3d(0.95,0.95,1);
}

figure.effect-selena h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,20px,0);
	transform: translate3d(0,20px,0);
}

figure.effect-selena p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg);
	transform: perspective(1000px) rotate3d(1,0,0,90deg);
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
}

figure.effect-selena:hover h2 {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-selena:hover p {
	opacity: 1;
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
	transform: perspective(1000px) rotate3d(1,0,0,0);
}