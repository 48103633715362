.is-clickable{
    cursor: pointer;
}

#main{
    padding-top: 110px;
}
#content{
    margin-top: 180px;
}

.img-banner-animation{
    position: relative;
    top: 60%;
}
.inspiration-image {
    overflow: hidden;
    max-height: 650px;
}
.inspiration-image .container-fluid {
    height: 100%;
}

.inspiration-image .row {
    height: 0;
    position: relative;
    padding-bottom: 38.69047619047619%;
}

.inspiration-image .image-holder {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-size: cover;
    max-height: 650px;
    background-position: center;
}

.inspiration-banner{
    display: inline-block;
    height: 70px;
	-webkit-backface-visibility: hidden;
    transform: skewX(-23deg);
}

.inspiration-banner h1{
    position: relative;

    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
    margin-left: 40px;
    padding-right: 30px;
    top: -4px;
    bottom: -55%;
    color: #fff;
	-webkit-backface-visibility: hidden;
    transform: skewX(23deg);
}

/* -------------- Search and Language Fields ----------------- */

#search-dialog .form-control,
#login-dialog .form-control,
.cse-search .form-control{
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #555;
    background-color: #f0f3f5;
    background-image: none;
    border: none;
    border-radius: 4px;
    box-shadow: inset 0 3px 5px -1px #8f9495;
    font-family: RobotoCondensedLight, sans-serif;
    outline: none;
}

.formular-section.no-margin-top{
	padding-top: 0;
}

#search-dialog button.mfp-close,
#login-dialog button.mfp-close,
#language-dialog button.mfp-close{
    z-index: 0;
}

#search-dialog span.mfp-close,
#language-dialog span.mfp-close,
#login-dialog span.mfp-close,
#booking-offline-dialog span.mfp-close{
    color: #fff;
    position: absolute;
    right: 0;
    top: -3px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #dc0014;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    opacity: 1;
    transition: all 0.1s linear;
    z-index: 5;
    cursor: pointer;
}

#search-dialog span.mfp-close:hover,
#language-dialog span.mfp-close:hover,
#login-dialog span.mfp-close:hover,
#booking-offline-dialog span.mfp-close:hover{
    background-color: #c80012;
    border-bottom-left-radius: 50%;
}

#search-dialog span.mfp-close{
    z-index: 2;
}

.mfp-content span{
    position: absolute;
    top: -5px;
    right: 12px;
    z-index: 5;
    color: #fff;
    font-size: 28px;
}

#search-dialog i{
    position: absolute;
    font-size: 18px;
    padding-left: 17px;
    top: 18px;
    right: 16px;
}

#search-dialog h1,
#language-dialog h1,
#login-dialog h1,
#booking-offline-dialog h1{
    color: #0064aa;
    font-family: RobotoCodensedLight, sans-serif;
    padding-bottom: 15px;
}

#language-dialog a{
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 22px;
    color: #333;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px;
    transition: all 0.1s linear;
}

#language-dialog a:hover{
    background-color: #eee;
}

#language-dialog .container-fluid,
#booking-offline-dialog .container-fluid{
    padding-left: 0;
    margin-left: -10px;
}

#search-dialog, #language-dialog, #booking-offline-dialog, #login-dialog{
    overflow: hidden;
}

/* ----------------------------------------- Darkpage ----------------------------------------- */

.center-darkpage{
    text-align: center;
}

.darkpage .lined-headline:before{
    right: 35%;
    width: 30%;
}

/* ----------------------------------------- Headline ----------------------------------------- */

.headline-container h1{
    font-size: 60px;
    font-family: Oswald-Regular, sans-serif;
    color: #0064aa;
    padding-bottom: 15px;
    margin-bottom: 50px;
}

.lined-headline{
    position: relative;
}
.lined-headline:before{
    content: '';
    position: absolute;
    bottom: -20px;
    width: 40%;
    border-bottom: 3px solid;
}

.headline-schneebericht h2,
.headline-live-reports h2{
    font-size: 36px;
    font-family: RobotoCondensedLight, sans-serif;
    color: #333;
    padding-bottom: 8px;
}

.headline-weather-today h2{
    font-size: 36px;
    font-family: RobotoCondensedLight, sans-serif;
    color: #333;
}


.headline-weather-next-days h3{
    font-size: 24px;
    font-family: RobotoCondensedLight, sans-serif;
    color: #333;
    margin-top: 10px;

}

/* ----------------------------------------- Content ----------------------------------------- */
img:focus,
a:focus{
    outline: none;
}

.slider.loading{
    min-height: 400px;
    display: block;
    position: relative;
    background: url(/static/img/ajax-loader.gif) no-repeat 50% 50%;
}

.content-block{
    margin-bottom: 25px;
}

.intro-text{
    margin-top: 50px;
    margin-bottom: 30px;
}
.intro-text{
    font-size: 36px;
    font-family: SourceSansPro-Light, sans-serif;
}

.content-block .wysiwyg-block{
    font-size: 17px;
    font-family: SourceSansPro-Light, sans-serif;
}

.content-block h2{
    font-family: Oswald-Regular, sans-serif;
    font-size: 24px;
}

.content-block h3{
    margin-top: 0;
    font-family: RobotoBold, sans-serif;
    font-size: 17px;
}

/*  ----------------------------------------- Lists -----------------------------------------*/

.wysiwyg-block ol {
    display: block;
    list-style-type: decimal;
    padding-left: 25px;
}
.wysiwyg-block ol > li{
    font-family: SourceSansPro-Light, sans-serif;
    margin-bottom: 15px;
}

.wysiwyg-block ol > li:before{
    font-family: RobotoBold, sans-serif;
}

.wysiwyg-block ul{
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.wysiwyg-block ul > li{
    font-family: SourceSansPro-Light, sans-serif;
    margin-bottom: 15px;
    padding-left: 25px;
    position: relative;
}
.wysiwyg-block ul > li:before{
    content: '';
    width: 6px;
    height: 6px;
    top: 2px;
    background: #dc0014;
    margin-top: 6px;
    position: absolute;
    left: 5px;
}



/* ----------------------------------------- Images and Galleries ----------------------------------------- */

.image-pulled-left{
    margin-right: 42px;
    margin-bottom: 10px;
}

.image-pulled-right{
    margin-left: 42px;
    margin-bottom: 10px;
}

.gallery-thumb-single{
    margin-bottom: 30px;
}

.img-lightbox{
	position: relative;
	display: inline-block;
	overflow: hidden;
}

.img-lightbox:before{
      content: '';
      position: absolute;

      background: rgba(0,70,120,.5);
      z-index: 1;

      opacity: 0;

      bottom: 0;
      left: 0;
      right: 0;
      top: 0;

}

a.img-lightbox:hover:before {
    opacity: 1;
}

.img-lightbox:hover:after {
    font-family: iconfont;
    content: "\e00b";
    color: #dc0014;
    position: absolute;

    z-index: 1;

    bottom: 13px;
    right: 15px;
    padding: 8px 12px 8px 12px;
    background-color: #fff;
}


.lightbox-gallery img {
    transition: all 0.3s ease-in-out;
}

.lightbox-gallery img:hover{
    /*box-shadow: 0 0 7px 1px #b7b7b7;*/
    border-radius: 15%;
}


.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a,
.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a{
    border-left: none;
    border-right: none;

    font-family: iconfont;
    content: "\E002";
    color: #fff;
    font-size: 30px;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a,
.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a{

    width: 40px;
    /*background-color: #252525;*/
    background-color: transparent;
    padding-top: 54px;
    padding-bottom: 96px;

/*
    width: 120px;
    background-color: #dc0014;
    padding-top: 232px;
    padding-bottom: 277px;
    padding-left: 64px;
    border-radius: 50%;
    */
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a{
    transform: scaleX(-1);
}

.mfp-arrow:after,
.mfp-arrow .mfp-a{
    top: -61px;
}

.mfp-arrow:before{
    content: initial;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a{
    margin-left: 10px;
}

/* ----------------------------------------- Slider (Slick) ----------------------------------------- */

.slick-slide{
    position: relative;
}
.slider-info{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(51,51,51, .85);
    color: #fff;
    padding: 15px 20px;
}
.slick-arrows button{
    display: inline-block;
    position: relative;
    left: auto;
    right: auto;
}

.slick-arrows button:before{
    color: #9f9f9f;
}

.slick-arrows .slick-prev{
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: solid 1px;
    border-right-color: #d9d9d9;
}

.slick-arrows .slick-next{
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: solid 1px;
    border-left-color: #d9d9d9;
}

.open-slider-text{
	cursor: pointer;
    right: 144px;
    bottom: 40px;
    position: absolute;

    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #fff;
}

.open-slider-text .icon-gal-info{
    transition: all .1s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -9px;
    margin-top: -6px;
    font-size: 12px;
    color: #9f9f9f;
}

.slick-arrows .slick-prev:before,
.slick-arrows .slick-next:before{
    content: '\e002';
    font-family: 'iconfont';
    font-size: 14px;
    transition: all .1s linear;
}

.slick-arrows .slick-prev:hover:before,
.slick-arrows .slick-next:hover:before,
.open-slider-text:hover i{
    color: #333;
}

.slick-arrows{

    position: absolute;
    right: 44px;
    bottom: 40px;
    display: block;
}

.open-slider-info{
    cursor: pointer;
}

.slider-info .description{
    width: 70%;
}

.slider-info-animation{
    transition: all .3s linear;
}

.img-copyright {
    color: #bfbfbf;
    font-family: RobotoLight, sans-serif;
    font-size: 12px;
    padding-top: 5px;
}


/* ----------------------------------------- Tables ----------------------------------------- */

.table-responsive{
    border: 0;
}

.table-style table{
    border-color: transparent;
}

.table-style table > thead > tr > th{
    height: 52px;
    font-family: RobotoLight, sans-serif;

    background-color: #0064aa;
    color: #fff;

    font-weight: 700;
    font-size: 16px;

    border-color: transparent;
}

.table-style table > tbody > tr > td{
    height: 52px;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    background: #f2f2f2;
    text-align: center;
    padding: 10px;
}

.table-style table > tbody > tr,
.table-style table > thead > tr{
    border-bottom: 1px solid;
    border-color: #bfbfbf;
}

.table-style table > tbody th{
    padding-left: 20px;
    text-align: left;

    background: rgb(243,243,243);
    background: -moz-linear-gradient(left,  rgba(243,243,243,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(243,243,243,1)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left,  rgba(243,243,243,1) 0%,rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left,  rgba(243,243,243,1) 0%,rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left,  rgba(243,243,243,1) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right,  rgba(243,243,243,1) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#ffffff',GradientType=1 );

}


.table-style table > thead > tr > th:first-child{
    padding-left: 20px;
    text-align: left;
}

.table-style table > thead > tr > th{
    text-align: center;
}


/* ----------- Price Slider ------------- */

.price-slider-container{
	position: relative;
	overflow: hidden;
}

.price-slider-container .teaser-experience-ribbon{

	position: absolute;
	left: -10px;
	top: 20px;
	right: auto;
}


/* --------- Presse -----------*/

.lb-container h3{
    font-family: RobotoCondensedBold, sans-serif;
    color: #0064aa;
}

.mfp-ajax-holder .mfp-content{
    width: auto;
}

#lightbox button span{
    position: relative;
    top: -12px;
    left: 10px;
}

/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 768px - 992px ----------------------- */

@media (min-width: 768px) and (max-width: 992px) {

    .table-style table > thead > tr > th{
        font-size: 16px;
    }
    .table-style table > tbody > tr > td{
        font-size: 14px;
    }

    .content-block h2 {
        font-size: 21px;
    }

}


/* ----------------------- 0 - 768px ----------------------- */

@media (max-width: 768px) {

    #main {
        padding-top: 50px;
    }

    #content {
        margin-top: 50px;
    }

    .headline-container h1{
        font-size: 30px;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
    .lined-headline:before {
        bottom: 0;
    }

    .intro-text {
        font-size: 24px;
        margin-top: 50px;
    }
    .slider-info .description {
        width: 100%;
    }

    .open-slider-text {
        width: 30px;
        height: 30px;
        right: 75px;
        bottom: 0;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
    }

    .open-slider-text .icon-gal-info {
        font-size: 11px;
        margin-left: -8px;
        margin-top: -5px;
    }

    .slick-arrows .slick-prev,
    .slick-arrows .slick-next {
        width: 30px;
        height: 30px;
        border-bottom-color: #d9d9d9;
    }

    .slick-arrows .slick-prev:before,
    .slick-arrows .slick-next:before {
        font-size: 12px;
    }

    .slick-arrows .slick-next {
        border-right-color: #d9d9d9;
    }

    .slick-arrows {
        position: absolute;
        right: 15px;
        bottom: 0px;
        display: block;
    }

    .table-style table>tbody th,
    .table-style table > tbody > tr > td,
    .table-style table > thead > tr > th{
        font-size: 12px;
    }

    .table-style table > thead > tr > th:first-child,
    .table-style table > tbody > tr > td:first-child,
    .table-style table>tbody th{
        padding-left: 5px;
    }

    .table-style table > thead > tr > th{
        padding-left: 8px;
        padding-right: 8px;
    }

    .content-block-summarized {
        font-size: 22px;
        margin-top: 100px;
        margin-bottom: 30px;
    }

    .content-block h2 {
        font-size: 20px;
    }

    .image-pulled-left {
        margin-right: 18px;
        margin-bottom: 3px;
    }

    .image-pulled-right {
        margin-left: 0;
        margin-bottom: 3px;
        width: 100%;
    }

    .image-pulled-right img,
    .image-pulled-left img {
        width: 250px;
        padding-bottom: 10px;
    }

    .wysiwyg-block .img-content{
        height: 155px;
    }

    .price-slider-container .teaser-experience-ribbon span{
        margin-left: 18px;
        padding-right: 15px;
    }

}

/* ----------------------- 0 - 454px ----------------------- */

@media (max-width: 454px) {

    .image-pulled-right img,
    .image-pulled-left img{
        width: 140px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}
