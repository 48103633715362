
/* ----------------------------------------- Schneebericht Content ----------------------------------------- */


.snow-values-bar,
.snow-value-info-bar{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(243,243,243,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
    border: 1px solid;
    border-color: #d9d9d9;
    padding-bottom:10px;
}
.snow-value-info-bar{
    clear:both;
    float:none;
    padding:30px;
}
.img-snow-skala-low,
.img-snow-skala-middle,
.img-snow-skala-high{
    margin-top: 35px;
}

.snow-value-text{
    font-size: 36px;
    font-family: RobotoCondensedBold,sans-serif;

    text-align: center;
}

.snow-value-lage{
    color: #004678;

    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    text-align: center;
}

.snow-value-tal,
.snow-value-berg,
.snow-value-lawine,
.snow-value-snow{
    text-align: center;
}

.snow-value-lawine{
    margin-right: -40px;
}

@media (min-width: 992px) {
    .snow-value-tal{
        margin-left: -15px;
    }
}

.snow-value-lawine .img-avalanche {
    margin:15px auto 0 auto;
    height:100px;
    width: auto;
    display: block;
}

.snow-value-snow .icon-nav-snow{
    color: #6aa6d1;
    font-size: 32px;
    margin-top: 15px;
    padding-right: 0;
}

.new-snow{
    width: 90%;
    color: #004678;
    text-align: center;
    font-family: RobotoLight,sans-serif;
    font-size: 16px;
    margin-top: 45px;
    margin-left: auto;
    margin-right:auto;
    padding-bottom:10px;
    border-bottom: 1px solid #d9d9d9;
}

/*.new-snow:after{*/
    /*height: 1px;*/
    /*width: 75%;*/
    /*position: absolute;*/
    /*content: '';*/
    /*background: #d9d9d9;*/
    /*margin-top: 14px;*/
    /*top: 56%;*/
    /*right: 8%;*/
    /*bottom: 0;*/
/*}*/

.snow-value-tal .col:after,
.snow-value-lawine .col:after,
.snow-value-berg .col:after{
    position: absolute;
    content: '';
    width: 1px;
    background: #d9d9d9;
    margin-top: 14px;
    top: 5%;
    right: 0;
    bottom: 5%;
}
.snow-value-lawine .col:after{
    right: auto;
    left:0;
}
.last-snow{
    width: 90%;
    color: #004678;
    text-align: center;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    margin: 20px auto 0 auto;
}

.live-reports{
    padding-top: 20px;
}


.teaser-live-reports h2{
    padding-top: 2px;
    margin-bottom: 3px;
}

.teaser-live-reports .teaser-img{
    padding: 10px;
}

.teaser-live-reports .teaser-content{
    padding-left: 25px;
    padding-right: 10px;
}

.snow-values-bar .icon-snow-mountain{
    font-size: 75px;
    color: #175693;
}

.snow-report-icons{
    color: #6aa6d1;
    margin-top: 36px;
    margin-left: -15px;

}

.snow-value-tal .icon-snow-arrow{
    z-index: 1;
}

.snow-value-berg .icon-snow-arrow{
    position: relative;
    bottom: 66px;
    left: 32px;
    z-index: 1;
}

.snow-value-snow  .icon-nav-snow:before{
    font-family: iconfont;
}

.snow-value-snow .icon-nav-snow.hvr-icon-spin:before{
    position: absolute;
    right:auto;
    left:50%;
    margin-left:-25px;
    width: 50px;
    text-align: center;
}

/* ----------------------------------------- Wetter & Prognosen  Content ----------------------------------------- */

/*
.weather-bar-today,
.weather-bar-tomorrow,
.weather-bar-uebermorgen{
    height: 214px;
}
*/

.weather-date-today{
    display: inline-block;
    margin-left: 3px;
    font-size: 24px;
    font-family: RobotoCondensedLight, sans-serif;
}
.weather-date-next-days{
    display: inline-block;
    font-size: 16px;
    font-family: RobotoCondensedLight, sans-serif;
    color: #333;
    margin-left: 3px;
}

/*
.weather-bar-tomorrow,
.weather-bar-uebermorgen{
    width: 97%;
}


.weather-bar-uebermorgen{
    margin-left: 3%;
}
*/

.weather-bar-next-2days,
.weather-bar-next-days{
    margin-top: 30px;
}

.weather-bar-today,
.weather-bar-tomorrow,
.weather-bar-uebermorgen{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(243,243,243,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
    border: 1px solid;
    border-color: #d9d9d9;
}

.weather-bar-today{
    padding-bottom:10px;
}


.weather-today-vormittag,
.weather-today-mittag,
.weather-today-abend{
    text-align: center;
}

.img-weather-today-vormittag,
.img-weather-today-mittag,
.img-weather-today-abend{
    margin-top: 35px;
}

.weather-today-grad{
    font-size: 36px;
    font-family: RobotoCondensedBold,sans-serif;

    text-align: center;
}

.weather-today-tageszeit{
    color: #004678;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    text-align: center;
}

.weather-today-temperature-min,
.weather-today-temperature-max,
.weather-today-wind{
    color: #004678;
    text-align: center;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

.weather-today-vormittag .col-xs-12:after,
.weather-today-mittag .col-xs-12:after,
.weather-today-abend .col-xs-12:after,
.weather-today-vormittag .col-sm-6:after,
.weather-today-mittag .col-sm-6:after,
.weather-today-abend .col-sm-6:after,
.weather-today-vormittag .col-md-3:after,
.weather-today-mittag .col-md-3:after,
.weather-today-abend .col-md-3:after{
    position: absolute;
    content: '';
    width: 1px;
    background: #d9d9d9;
    margin-top: 14px;
    top: 5%;
    right: 0;
    bottom: 5%;
}

.weather-today-temperature-max:after{
    height: 1px;
    width: 150px;
    position: absolute;
    content: '';
    background: #d9d9d9;
    margin-top: 16px;
    top: 66%;
    left: -46px;
    bottom: 0;
}

.weather-today-wind {
    margin-top: 176px;
    margin-left: -10px;
    width: 100%;
}


.weather-more .icon-temp,
.weather-more-next-days .icon-temp{
    position: absolute;
    color: #6aa6d1;
    font-size: 44px;
    margin-top: 52px;
    margin-left: 33px;
}

.weather-more .icon-temp:before,
.weather-more-next-days .icon-temp:before{
    font-family: iconfont;
}

.hvr-icon-fade:before{
    right: auto !important;
}

.hvr-icon-fade:hover:before,
.hvr-icon-fade:focus:before,
.hvr-icon-fade:active:before{
    color: #0064aa !important;
}

.weather-more .icon-wind,
.weather-more-next-days .icon-wind{
    position: absolute;
    color: #6aa6d1;
    font-size: 20px;
    margin-top: 144px;
    margin-left: 60px;
}

.weather-today-temperature-min,
.weather-next-days-temperature-min{
    margin-top: 38px;
    margin-left: 61px;
    position: absolute;
}

.weather-today-temperature-max,
.weather-next-days-temperature-max{
    margin-top: 90px;
    margin-left: 61px;
    position: absolute;
}

.weather-today-grad-zahl-min,
.weather-today-grad-zahl-max{
    position: absolute;
    font-size: 36px;
    font-family: RobotoCondensedBold,sans-serif;

    margin-left: 105px;

}

.weather-today-grad-zahl-min{
    margin-top: 18px;
}

.weather-today-grad-zahl-max{
    margin-top: 71px;
}

.img-weather-box{
    text-align: center;
}

.weather-day{
    width: 300px;
}

.img-weather-tomorrow{
    margin-top: 28px;
    padding-bottom: 30px;
}

.weather-next-days-temperature-min,
.weather-next-days-temperature-max,
.weather-next-days-wind {
    color: #004678;
    text-align: center;
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

.weather-next-days-grad-zahl-min{
    position: absolute;
    font-size: 24px;
    font-family: RobotoCondensedBold, sans-serif;

    margin-left: 102px;
    margin-top: 29px;

}

.weather-next-days-grad-zahl-max{
    position: absolute;
    font-size: 24px;
    font-family: RobotoCondensedBold, sans-serif;

    margin-left: 102px;
    margin-top: 82px;
}

.weather-next-days-temperature-max:after{
    height: 1px;
    width: 150px;
    position: absolute;
    content: '';
    background: #d9d9d9;
    margin-top: 16px;
    top: 66%;
    left: -47px;
    bottom: 0;
}

.weather-next-days-wind {
    position: absolute;
    margin-top: 176px;
    margin-left: -30px;
    width: 100%;
}

.weather-bar-today img,
.weather-bar-next-2days img{
    width: 60%;
}

.weather-more-next-days .icon-wind{
    margin-left: 53px;
}

.temp-block{
    margin-left: -10px;
}

/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 991px - 1199px ------------z.B. iPad----------- */
@media (min-width: 991px) and (max-width: 1200px) {

    .weather-more{
        left: -2px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    /* Weather */

    .weather-more{
        margin-left: 38px;
    }

    .weather-more-next-days .icon-wind {
        margin-left: 87px;
        margin-top: 142px;
    }

    .img-weather-today-vormittag,
    .img-weather-today-mittag,
    .img-weather-today-abend,
    .img-weather-tomorrow{
        margin-top: 0;
    }

    .temp-block{
        margin-left: 30px;
    }

    .weather-next-days-wind{
        width: 100px;
        margin-left: 60px;
    }

    .weather-today-temperature-max:after{
        left: -46px;
    }

    .weather-next-days-temperature-max:after {
        left: -54px;
    }

    /* Schneebericht */

    .new-snow,
    .last-snow{
        width: 130px;
        font-size: 14px;
    }

    /*.new-snow:after {*/
        /*top: 108px;*/
        /*width: 85%;*/
        /*right: 0;*/
        /*left: 74px;*/
        /*bottom: 0;*/
    /*}*/


}

/* ----------------------- 0 - 991px ----------------------- */

@media (max-width: 991px) {

    /* Media Queries Schneebericht Beginn */

   /* .snow-values-bar {
        height: 394px;
    }
    */

    .img-snow-skala-low,
    .img-snow-skala-middle,
    .img-snow-skala-high {
        margin-top: 27px;
    }

    .new-snow{
        padding-top: 10px;
    }

    .snow-value-text {
        font-size: 28px;
    }

    .snow-value-lage {
        font-size: 14px;
    }

    .weather-today-wind{
        width: 100px;
        margin-left: 60px;
    }

    .snow-value-snow .icon-nav-snow {
        font-size: 32px;
        padding-top: 5px;
    }

    .snow-value-berg .col:after {
        width: 0px;
    }

    /* Media Queries Weather Beginn */

    .weather-more .icon-wind {
        margin-left: 90px;
    }

    .weather-today-mittag .col-xs-12:after,
    .weather-today-mittag .col-sm-6:after,
    .weather-today-mittag .col-md-3:after{
        width: 0px;
    }


    .weather-bar-tomorrow,
    .weather-bar-uebermorgen{
        width: 100%;
    }

    .weather-bar-uebermorgen{
        margin-left: 0%;
        margin-top: 30px;
    }



}


/* ----------------------- 0 - 768px ----------------------- */

@media screen and  (max-width: 768px) {

    /* Media Queries Schneebericht Beginn */
    .headline-schneebericht h2 {
        font-size: 28px;
        margin-top: -24px;
    }

    .headline-live-reports h2 {
        font-size: 28px;
    }

    .teaser-reports .content-block h2 {
        font-size: 18px;
    }

    .teaser-live-reports .teaser-content{
        padding-left: 0px;
    }

    .teaser-live-reports .content-block{
        margin-bottom: 0;
    }

    .new-snow,
    .last-snow{
        width: 100%;
        font-size: 14px;
    }

    /* Media Queries Weather Beginn */

    .weather-more{
        left: 36px;
    }

    .headline-weather-today {
        margin-top: 0;
    }

    .weather-more-next-days .icon-wind {
        margin-left: 74px;
    }

   /* .weather-bar-today {
        height: 310px;
    }
    */

    .weather-next-days-wind {
        margin-left: -40px;
    }

    .img-weather-today-vormittag,
    .img-weather-today-mittag,
    .img-weather-today-abend,
    .img-weather-tomorrow {
        margin-top: 16px;
    }

    .img-weather-tomorrow {
        padding-bottom: 60px;
    }

    .temp-block {
        margin-left: 22px;
    }

    .weather-more-next-days .temp-block {
        margin-left: 16px;
    }
}


/* ----------------------- 0 - 514px ----------------------- */

@media screen and (max-width: 514px) {

    /* Media Queries Schneebericht Beginn */

    .live-reports-xs .content-block h2{
        font-size: 20px;
        margin: 0;
        margin-bottom: 3px;
    }

   /* .snow-values-bar {
        height: 320px;
    }
    */

    .snow-value-lage{
        font-size: 12px;
    }

    .snow-value-text {
        font-size: 22px;
    }

    .snow-value-snow .icon-nav-snow {
        font-size: 26px;
    }

    .img-snow-skala-low,
    .img-snow-skala-high {
        margin-top: 27px;
        width: 80px;
    }

    .img-snow-skala-middle {
        width: 80px;
    }

    .new-snow, .last-snow {
        font-size: 12px;
    }

    /*.new-snow:after {*/
        /*top: 94px;*/
        /*left: 55px;*/
    /*}*/

    .snow-value-tal .col:after,
    .snow-value-lawine .col:after,
    .snow-value-berg .col:after {
        width: 0px;
    }

    /* Media Queries Weather Beginn */

    .weather-more{
        left: -48px;
        top: -13px;
    }

    .weather-today-tageszeit,
    .weather-today-wind,
    .weather-today-temperature-min,
    .weather-today-temperature-max{
        font-size: 14px;
    }

    .weather-today-temperature-max {
        margin-top: 81px;
    }

    .weather-today-temperature-min {
        margin-top: 44px;
    }

    .weather-today-grad-zahl-min {
        margin-left: 97px;
        margin-top: 37px;
    }

    .weather-today-grad-zahl-max {
        margin-left: 97px;
        margin-top: 74px;
    }

    .weather-today-temperature-max:after{
        width: 108px;
        left: -30px;

    }

    .weather-today-grad{
        font-size: 22px;
    }

    .weather-today-tageszeit,
    .weather-today-wind,
    .weather-today-temperature-min,
    .weather-today-temperature-max,
    .weather-next-days-temperature-min,
    .weather-next-days-temperature-max,
    .weather-next-days-wind {
        font-size: 14px;
    }

    .weather-today-temperature-max,
    .weather-next-days-temperature-max{
        margin-top: 81px;
    }

    .weather-today-temperature-min,
    .weather-next-days-temperature-min{
        margin-top: 44px;
    }

    .weather-today-grad-zahl-min {
        margin-left: 98px;
        margin-top: 37px;
    }

    .weather-today-grad-zahl-max {
        margin-left: 98px;
        margin-top: 74px;
    }
    .weather-more .icon-wind{
        margin-top: 126px;
        margin-left: 88px;
    }

    .weather-today-wind{
        margin-top: 164px;
    }

    .block-tomorrow{
        margin-left: -40px;
    }

    .weather-next-days-temperature-max:after{
        left: -34px;
        width: 100px;
    }

    .weather-today-vormittag .col-xs-12:after,
    .weather-today-mittag .col-xs-12:after,
    .weather-today-abend .col-xs-12:after,
    .weather-today-vormittag .col-sm-6:after,
    .weather-today-mittag .col-sm-6:after,
    .weather-today-abend .col-sm-6:after,
    .weather-today-vormittag .col-md-3:after,
    .weather-today-mittag .col-md-3:after,
    .weather-today-abend .col-md-3:after {
        width: 0px;
    }

    .weather-next-days-grad-zahl-min {
        margin-left: 98px;
        margin-top: 35px;
    }

    .weather-next-days-grad-zahl-max {
        margin-left: 98px;
        margin-top: 74px;
    }

    .weather-next-days-wind {
        margin-top: 164px;
        margin-left: 14px;
    }

    .weather-more-next-days .icon-wind {
        margin-top: 133px;
    }

 /*   .weather-bar-tomorrow,
    .weather-bar-uebermorgen {
        height: 202px;
    }
    */
}

/* ----------------------- 341px - 410px ----------------------- */

@media screen and (min-width: 341px) and (max-width: 410px) {

    /* Media Queries Schneebericht Beginn */

    .live-reports-xs .col-xs-4{
        padding-right: 0;
    }

    /* Weather */

}


/* ----------------------- 0 - 360px ----------------------- */

@media screen and  (max-width: 360px) {

    /* Media Queries Schneebericht Beginn */

    .new-snow {
        margin-top: 45px;
        padding-bottom:0;
    }
    .last-snow {
        margin-top: 10px;
    }

    .img-snow-skala-middle {
        margin-left: 0%;
    }

    .img-snow-skala-low, .img-snow-skala-high {
        margin-left: -15px;
    }

    .snow-value-tal .snow-value-text,
    .snow-value-berg .snow-value-text {
        margin-left: -24px;
    }

    .snow-value-tal .snow-value-lage,
    .snow-value-berg .snow-value-lage {
        margin-left: -14px;
    }

    /* Media Queries Weather Beginn */

    .weather-today-temperature-max:after{
        width: 100px;
    }

    .weather-more-next-days .icon-wind {
        margin-left: 73px;
        margin-top: 129px;
    }

    .weather-more .icon-wind{
        margin-top: 134px;
        margin-left: 87px;
    }

    .temp-block {
        margin-left: 30px;
    }

    .weather-next-days-wind {
        margin-left: 16px;
    }
}

/* ----------------------- 0 - 340px ----------------------- */

@media screen and  (max-width: 340px) {

    /* Media Queries Schneebericht Beginn */

    .img-snow-skala-middle {
        margin-left: 5%;
    }

    .img-snow-skala-low, .img-snow-skala-high {
        margin-left: -2px;
    }

    .snow-value-tal .snow-value-text,
    .snow-value-berg .snow-value-text {
        margin-left: -10px;
    }

    .snow-value-tal .snow-value-lage,
    .snow-value-berg .snow-value-lage {
        margin-left: -4px;
    }

    .snow-report-icons {
        margin-left: -36px;
    }
    .snow-values-bar .icon-snow-mountain {
        font-size: 60px;
    }

    .snow-value-berg .icon-snow-arrow {
        bottom: 46px;
        left: 25px;
    }


    /* Media Queries Weather Beginn */

    .weather-today-wind{
        margin-top: 158px;
        margin-left: 53px;
    }

    .weather-more {
        left: -65px;
    }

    .weather-more .icon-wind {
        margin-top: 126px;
        margin-left: 84px;
    }

    .weather-next-days-wind {
        margin-left: 26px;
    }

    .img-weather-tomorrow {
        padding-bottom: 80px;
    }

    .block-tomorrow {
        margin-left: -52px;
        margin-top: 8px;
    }
}