
.search-restaurants{
    margin-top: 30px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    padding-top: 20px;
}

.checkbox-filter-restaurants {
    margin-top: 16px;
}

.restaurant-open,
.restaurant-closed{
    text-transform: uppercase;
}

.restaurant-info .details-calendar,
.restaurant-info .details-place,
.restaurant-info .details-people{
    font-family: RobotoLight, sans-serif;
}

.details-place .icon{
	padding-left: 5px;
}

.speisekarte-content h2,
.show-restaurants h2{
    margin-top: 0;
}

.full-banner-show-restaurants{
    padding-bottom: 300px;
    padding-top: 300px;
    background-position: center;
}

.full-banner-restaurant{
    background-image: url(/static/img/test-stock-img-2.jpeg);
    padding-bottom: 200px;
    padding-top: 200px;
    background-size: cover;
    background-position: center;

}

.restaurant-banner-testimonial{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 46px;
    color: #fff;
}

.zitat-person{
    font-family: RobotoLight, sans-serif;
    font-size: 20px;
}

.speisekarte .wysiwyg-block{
    overflow: hidden;
}

.menu-wrapper{
    border-bottom: 150px solid #0064aa;
    border-left: 150px solid transparent;
    bottom: 0;
    height: 0;
    /*opacity: .95;*/
    position: absolute;
    right: 15px;
    text-indent: -9999px;
    transition: all .5s ease-out;
    width: 0;
}

.speisekarte-img:hover .menu-wrapper{
     border-bottom: 800px solid #0064aa;
    border-left: 800px solid transparent;
    transition: all .5s ease-out;
 }

.corner-menu-wrapper-content{
    position: absolute;
    font-size: 18px;
    color: #fff;
    transform: rotate(-45deg);
    text-align: center;
    right:20px;
    bottom: 25px;
    width: 100px;
    transition: all .5s ease-out;
}

.speisekarte-img:hover .corner-menu-wrapper-content{
     opacity: 0;
    transition: all .5s ease-out;
 }

.speisekarte a{
    text-decoration: none;
}

.menu-wrapper-content{
    bottom: 0;
    color: #333;
    left: 15px;
    right: 15px;
    top: 0;
    opacity: 0;
    padding: 30px;
    position: absolute;
    transition: all .3s ease-out;
}

.menu-wrapper-content h2{
    color: #fff;
    padding: 0 0 12px;
}

.speisekarte-img:hover .menu-wrapper-content{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 16px;
    color: #fff;
     opacity: 1;
     transition: all .3s ease-out;
     transition-delay: .3s;
 }

.menu-wrapper-content:before {
    content: '';
    position: absolute;
    top: 86px;
    width: 40%;
    border-bottom: 1px solid #999;
}

/* --------------------------------------- MEDIA QUERY -------------------------------------------- */

/* ----------------------- 0 - 768px ----------------------- */

@media (max-width: 767px) {

    .teaser-category,
    .restaurant-info .details-calendar,
    .restaurant-info .details-place{
        font-size: 14px;
    }

    .full-banner-restaurant{
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .restaurant-banner-testimonial {
        font-size: 34px;
    }
}

.restaurant-info span{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}