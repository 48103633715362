.single-row{
    padding-left: 0;
    margin-left: 15px;
    padding-bottom: 36px;
    border-bottom: 1px solid #ddd;
}

.single-row h2{
    font-family: RobotoCondensedBold, sans-serif;
    color: #0064aa;
    font-size: 36px;
}

.paging-row{
    font-family: RobotoLight, sans-serif;
    margin-top: 25px;
}

.short-description{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    margin-top: 10px;
}

a.search-link {
    color: #666;
}

.single-row .read-more{
    position: relative;
    top: 10px;
}

.mfp-content figure{
    background: transparent;
}

.no-search-result-container {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
    .no-search-result-container {
        min-height: 180px;
    }
}
@media screen and (min-width: 1200px) {
    .no-search-result-container {
        min-height: 300px;
    }
}