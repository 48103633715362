.bg-helper{
    padding-bottom: 200px;
}

@media (max-width: 767px) {
    .bg-helper{
        padding-bottom: 80px;
    }

    .bg-helper-xs{
        background-size: 160% !important;
    }
}


.contact-address span,
.contact-open span {
    position: relative;
    top: 20px;
}

.contact-address a,
.contact-open a{
    text-decoration: none;
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 24px;
    color: #dc0014;
}

.formular-section{
    padding-top: 50px;
}
.formular-section h2{
    padding-bottom: 30px;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
}


.formular-section .select-anrede  select{
     height: 50px;
     width: 70%;
 }

.formular-section .select-country select{
    width: 100%;
}

.select-anrede,
.select-country,
.message-area{
    padding-bottom: 5px;
}

.formular-section .label,
.newsletter-anmeldung .label,
.anreise-content .label,
.filter-news .label{
    display: block;
    font-size: 16px;
    color: #666;
    font-family: RobotoCondensedLight, sans-serif;
    margin-bottom: 13px;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
}

.formular-section .message-area .form-control{
   height: 250px;
}

.contact-address{
    padding-bottom: 35px;
}

.newsletter-area{
    height: 250px;
    border: 1px solid #e6e6e6;
}

.content-newsletter{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
}

.cb-newsletter-abo{
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    margin-right: 22px;
}

.newsletter-anmeldung h2{
    padding-bottom: 20px;
}

.anreise-content h2{
    padding-bottom: 15px;
}

.anreise-content .icon-locator{
    color: #666;

    position: absolute;
    right: 24px;
    margin-top: -32px;
}

.anreise-map{
    padding-top: 30px;
}

.contact-submit{
    padding-bottom: 50px;
}


.formular-section .parsley-required{
    color: #dc0014;
    font-family: RobotoCodensedBold,sans-serif;
    list-style: none;
    position: relative;
}


.has-error .form-control,
select.has-error,
.form-control.has-error{
    border-color: #dc0014;
    outline: none;
}

/* ----------------------- 0 - 768px ----------------------- */
@media (max-width: 767px) {

    .anreise-content h2 {
        padding-bottom: 0;
    }

}