.container {
    max-width: 1200px;
    width: 100%;
}

#game-wrapper {
    width:980px;
    margin:0 auto;
    text-align:center;
    margin-bottom: 50px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
}


@media screen and (min-width: 768px) {

}

