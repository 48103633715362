
.darkpage .image-holder {
    background-position: 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
	background-image: url(/static/img/page-bg.jpg);
}

/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #fff;
	z-index: 200;
}
.overlay.powder{
	width: 300px;
	left: auto;
	right: 0;
	box-shadow: 0 0 30px rgba( 0, 0, 0, .7 );
}

@media screen and (min-width: 768px) {
	.overlay.powder{
		top: 140px;
	}
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 80px;
	height: 80px;
	position: absolute;
	right: 20px;
	top: 20px;
	background: none;
	overflow: hidden;
	border: none;
	outline: none;
	z-index: 100;
}

.powder .icon.icon-snow,
.powder .icon.icon-sun{
	font-size: 170px;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -85px;
	z-index: 2;
}
.powder .icon-snow:before{
	content: "\E019";
}
.powder .icon-snow{
	color: #e1edf6;
}
.powder .icon-sun{
	color: #ffe0b2;
}
.powder-image{
	position: absolute;
	top: -30px;
	z-index: 0;
}
.powder .darkpage.center-darkpage > div{
	z-index: 5;
}
.powder .intro-text{
	margin-top: 200px;
	position: relative;
	color: #004678;
}
.powder .intro-text span{
	display: block;
	text-align: center;
}
.powder .headline-container:before{
	content: none;
}
.powder .sonstiges-text{
	font-size: 45px;
	color: #0065aa;
	line-height: 45px;
	text-transform: uppercase;
}
.powder .headline-container{
	margin-top: 90px;
}
.powder .headline-container h2,
.powder .headline-container h1{
	font-family: RobotoCondensedBold, sans-serif;
	font-size: 65px;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
}
.powder.sun .headline-container h2{
	color: #ff9900;
}
.powder.snow .headline-container h2{
	color: #dc0015;
}
.powder .bold-text{
	font-family: RobotoCondensedBold, sans-serif;
	font-size: 55px;
	line-height: 45px;
}
.powder .normal-text{
	font-size: 30px;
}
.powder .close-btn:hover {
	text-decoration: none;
	color: #fff;
	box-shadow: 0 0 10px rgba( 0,0,0,.8 );
}
.powder .close-btn {
	padding-top: 13px;
}

/* Menu style */
.overlay .content {
	-webkit-backface-visibility: hidden;
	text-align: center;
	position: relative;
	top: 50%;
	height: 60%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay.powder .content {
	-webkit-backface-visibility: hidden;
	text-align: center;
	position: relative;
	top:0;
	height: auto;
	-webkit-transform: none;
	transform: none;
}

.move-nav-box-text.close-btn {
	z-index: 50;
	position: absolute;
	right: -10px;
	top: 0;
	background-color: #999999;
	margin: 0;
	padding: 0;
	width: 60px;
	height: 40px;
	transform: skewX(-25deg);

	-webkit-backface-visibility: hidden;

	transition: all 200ms ease-in-out;
}
.move-nav-box-text.close-btn span{
	transform: skewX(25deg);
	position: absolute;
	left: 20px;
	top: 10px;
}
.darkpage .move-nav-box-text.close-btn {
	top: 0;
	background-color: #999;
	width: 100px;
	height: 60px;
	transform: skewX(-3deg);
	transition: all 200ms ease-in-out;
}
.darkpage .move-nav-box-text.close-btn span {
	left: 30px;
	top: 20px;
}
.darkpage .move-nav-box-text.move-nav-box-text.close-btn:hover {
	background: #999;
}
/* Effects */
.overlay-hugeinc {
	opacity: 0;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay-hugeinc.open {
	opacity: 1;
	visibility: visible;
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.overlay-hugeinc .content {
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1200px;
	perspective: 1200px;
}
.overlay-hugeinc .content-ani {
	-webkit-backface-visibility: hidden;
	opacity: 0.4;
	-webkit-transform: translateY(-25%) rotateX(35deg);
	transform: translateY(-25%) rotateX(35deg);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
}

.overlay-hugeinc.open .content-ani{
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.overlay-hugeinc.close .content-ani{
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(25%) rotateX(-35deg);
	transform: translateY(25%) rotateX(-35deg);
}

@media screen and (max-height: 30.5em) {
	.overlay .content {
		height: 70%;
		font-size: 34px;
	}
}

@media (max-width: 454px) {
	.powder .sonstiges-text{
		font-size: 35px;
		line-height: 35px;
	}

	.powder-image{
		top: -30%;
	}
	.powder .intro-text{
		margin-top: 80px;
	}
	.powder .headline-container{
		margin-top: 70px;
	}
}