body {
    font-family: SourceSansPro-Light, sans-serif;
    font-size: 17px;
}

textarea {
    resize: vertical;
}

/* hide google conversion frame  */
iframe[name=google_conversion_frame] {
    position: absolute;
    left:-19999px;
}
#pc-cookie-notice {
    font-size: 13px!important;
    padding: 9px 0!important;
}
#pc-cookie-notice #pc-message {
    padding: 0 155px 0 25px!important;
}
#pc-button {
    top: 5px!important;
    bottom: 0!important;
}
@media screen and (max-width: 767px) {
    #pc-cookie-notice #pc-message {
        padding: 0 15px !important;
        text-align: center;
    }
    #pc-button {
        margin-top: 10px;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        text-align: center;
    }
}