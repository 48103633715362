.center-text{
    text-align: center;
}

.open-tracks-last-stand{
    margin-top: 60px;
    color: #004678;
}

/* ----------------------------------------- Tabs Open Tracks ----------------------------------------- */

.open-tracks-tabs{
    margin-top: 30px;
}

.open-tracks-tabs ul > li{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(242,242,242,1)));
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=1 );
}


.open-tracks-tabs ul > li > a:hover{
    background: #fff;
}

.open-tracks-tabs ul > li {
    color: #666;
    margin-right: 4px;
    font-family: RobotoCondensedLight, sans-serif;
}

.open-tracks-tabs ul > li > a {
    color: #666;
    font-size: 20px;
    margin-right: 0px;
}

.open-tracks-tabs ul > li:first-child {
    margin-left: 17px;
}

.open-tracks-tabs .nav-tabs > li > a {
    border-radius: 0;
    border: 1px solid #ddd;
}

.open-tracks-tabs ul > li.active > a:hover,
.open-tracks-tabs ul > li.active > a:link {
    background: #fff;
    border-bottom-color: transparent;
}

.open-number-park,
.open-number-ski,
.open-number-hiking,
.open-number-lifts{
    font-weight: 700;
}


/* ----------------------------------------- Table Open Tracks ----------------------------------------- */

.open-lifts,
.open-ski-tracks,
.open-tracks-hiking{
    margin-top: 40px;
}


.table-open-lifts table > thead > tr > th,
.table-open-skitracks table > thead > tr > th,
.open-tracks-hiking table > thead > tr > th{
    padding-top: 15px;
    padding-bottom: 15px;

    font-family: RobotoBold,sans-serif;

    background-color: #0064aa;
    color: #fff;
}



.table-open-lifts table > tbody > tr > td:first-child span,
.table-open-hiking table > tbody > tr > td:first-child span,
.table-open-skitracks table > tbody > tr > td:first-child span{
    color: #004678;
    font-family: RobotoBold, sans-serif;

    margin-left: 15px;
}

.table-open-lifts table > tbody > tr > td{
    padding-top: 10px;
    padding-bottom: 10px;
}

.table-striped>tbody>tr:nth-of-type(odd):hover,
.table-striped tr:hover{
    background-color: #f2f2f2;
}

.open-tracks-content .icon-status-opened,
.open-tracks-content .icon-status-closed{
    vertical-align: middle;
    padding-right: 8px;
    font-size: 20px;
}

.open-tracks-content .icon-nav-webcams{
    font-size: 18px;
    padding-right: 5px;
}

.open-tracks-content .icon-status-opened{
    color: #08aa00;
    margin-top: -4px;
}

.open-tracks-content .icon-status-closed{
    color: #dc0014;
    margin-top: -4px;
}

.new-place-border-top{
    border-top: 2px solid;
    border-top-color: #004678;
}


.table-open-skitracks table > tbody > tr > td:nth-child(3){
    padding-left: 45px;
}

.skitrack-leicht,
.skitrack-blue{
    background-color: #004678;
}
.skitrack-mittel,
.skitrack-red{
    background-color: #dc0014;
}
.skitrack-schwer,
.skitrack-black{
     background-color: #000000;
 }
.skitrack-yellow{
    background-color: #ffa200;
}

.circle-skitracks{
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 8px;
    display: inline-block;
}

.square-skitracks{
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    display: inline-block;
}

.space-difficulty{
    margin-left: 10px;
}



/* ----------------------------------------- Checkboxes ----------------------------------------- */


.checkbox-filter-tracks{
    margin-top: 30px;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox] + label {
    padding-left: 20px;
    font-weight: normal;
    margin-bottom: 0;
    position: relative;
}


input[type=checkbox] + label:before {
    content: "";
    display: inline-block;

    width: 15px;
    height: 15px;
    vertical-align:middle;
    margin-right: 8px;
    background-color: #efefef;
    /*box-shadow: inset 0px 2px 2px rgba(0, 0, 0, .3);*/
    border: 1px solid #666;
}


input[type=checkbox] + label:after {
    display: none;
}

input[type=checkbox]:checked + label{

}

input[type=checkbox]:checked + label:after {
    content:"\E005";
    font-family: 'iconfont';
    color: #0064aa;
    display: inline-block;
    position: absolute;
    left: 22px;
    top: 0;
    width: 15px;
    height: 15px;
    vertical-align:middle;
}

.cb-filter input[type=checkbox]:checked + label:before{
    position: relative;
}

.cb-filter{
    display: inline-block;
}


/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 0 - 768px ----------------------- */
@media (max-width: 768px) {
    .cb-filter{
        display: block;
    }

    .cb-filter input[type=checkbox]+label{
        padding-left: 0;
    }

    input[type=checkbox]:checked + label:after {
        left: 0;
    }

    .cb-newsletter-abo input[type=checkbox]:checked + label:after{
        left: 22px;
    }

    .open-tracks-tabs ul > li {
        width: 150px;
    }

    .open-tracks-tabs ul > li > a {
        font-size: 18px;
    }

    .open-tracks-tabs ul > li:first-child {
        margin-left: 0px;
    }

    .open-tracks-tabs .nav-tabs > li > a {
        min-height: 97px;
    }

}

@media (max-width: 360px) {

    .open-tracks-tabs ul > li {
        width: 125px;
    }
}

