/* fullscreen webcam */

.fullscreen-webcam .slick-arrows {
    position: relative;
    right: 0px;
    bottom: 0px;
    display: block;
}

.fullscreen-webcam .slick-arrows .slick-prev {
    display: inline-block;
    width: 40px;
    height: 90px;
    left: -15px;
    background-color: #fff;
    border: solid 1px;
    border-right-color: #d9d9d9;
}

.fullscreen-webcam .slick-arrows .slick-next {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 90px;
    right: -15px;
    float: right;
    background-color: #fff;
    border: solid 1px;
    border-left-color: #d9d9d9;
}


.fullscreen-webcam .slick-prev,
.fullscreen-webcam .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 300px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}


.fullscreen-webcam .slick-arrows .slick-prev:before,
.fullscreen-webcam .slick-arrows .slick-next:before {
    content: '\e002';
    font-family: 'iconfont';
    font-size: 26px;
    margin-left: 5px;
    /* position: absolute; */
}



.webcam-fullscreen-headline{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 60px;
    color: #0064aa;

    text-align: center;
}



/* ---------- */

.webcam-slider-nav .slick-slide,
.webcam-slider .slick-slide{
	cursor: pointer;
}
.webcam-slider .webcam-slider-nav{
    margin-top: 15px;
}


.webcam-slider-nav .slick-prev,
.webcam-slider-nav .slick-next{
    background-color: #000;
    border: 1px solid #d9d9d9;

    width: 30px;
    height: auto;
    position: absolute;
    bottom: 4px;
    top: 10px;
}

.webcam-slider-nav .slick-next:before{
    content: "\E002";
    font-family: 'iconfont';
    color: #999;
}

.webcam-slider-nav .slick-prev:before{
    content: "\E002";
    font-family: 'iconfont';

    color: #999;
}

.webcam-slider-nav .slick-prev{
     margin-left: 25px;
}

.webcam-slider-nav .slick-next{
    margin-right: 24px;
}


.webcam-slider-nav .slick-slide.slick-active.slick-center span{
    display: inline-block;
    position: relative;
}

.webcam-slider-nav .slick-slide.slick-active.slick-center span:before{

    content: '';
    position: absolute;
    background: rgba(220,0,20,.3);
    z-index: 1;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    -webkit-box-shadow:inset 0 13px 0 -10px #dc0014;
    box-shadow:inset 0 13px 0 -10px #dc0014;

}

.webcam-slider-nav .slick-prev,
.webcam-slider-nav .slick-next{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(242,243,242,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(242,243,242,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f3f2',GradientType=0 );

}


.arrow_box {
    position: relative;
    background: #dc0014;
}
.arrow_box:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(220, 0, 20, 0);
    border-bottom-color: #dc0014;
    border-width: 10px;
    margin-left: -10px;
}

.webcam-caption{
    position: relative;
    height: 60px;
    border: 1px solid #d9d9d9;
}

.webcam-description span{
    position: relative;
    /*top: 12px;*/
}


.webcam-description{
    margin-left: 20px;
    margin-top: 13px;
}

.webcam-mountain{
    display: inline-block;
}

.webcam-counter{
    /*display: inline-block;*/
    /*float: right;*/
    /*position: relative;*/
    /*top: -32px;*/
    position: absolute;
    right: 0;
    top: 14px;
}

.webcam-place,
.counter-numbers{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 24px;
}

.webcam-txt,
.webcam-altitude,
.counter-hashtag{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

.counter-numbers{
    margin-right: 15px;
}

.webcam-description .counter-hashtag{
    color: #999;
    position: relative;
    margin-top: 7px;
    padding-right: 5px;
}

/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 0 - 768px ----------------------- */
@media (max-width: 768px) {

   .webcam-mountain{
       display: block;
   }

    .webcam-counter {
        display: block;
        float: left;
    }

    .webcam-caption {
        height: 100px;
    }

    .webcam-description span{
        top: 0;
    }

    .webcam-slider-nav .slick-list.draggable {
        display: none;
    }

    .webcam-slider-nav .slick-prev,
    .webcam-slider-nav .slick-next {
        background: transparent !important;
        border: none;
        width: 25px;
        height: 30px;
        margin-top: -63%;
    }

   .webcam-slider-nav .slick-next{
        right: -52px;
    }

    .webcam-slider-nav .slick-prev{
        left: -52px;
    }

    .slick-prev:before, .slick-next:before {
        font-size: 14px;
    }

    .webcam-slider-nav .slick-prev:before,
    .webcam-slider-nav .slick-next:before{
        color: #333;
    }
    .webcam-altitude{
	    display: block;
    }
}

/* ----------------------- 0 - 514px ----------------------- */

@media (max-width: 514px) {

    .webcam-place, .counter-numbers {
        font-size: 20px;
    }

    .webcam-description .counter-hashtag {
        margin-top: 4px;
    }

}

/* ----------------------- 0 - 400px ----------------------- */
@media (max-width: 400px) {

    .webcam-slider-nav .slick-prev,
    .webcam-slider-nav .slick-next {
        margin-top: -70%;
    }

}

/* ----------------------- 0 - 359px ----------------------- */
@media (max-width: 359px) {

    .webcam-slider-nav .slick-prev,
    .webcam-slider-nav .slick-next {
        margin-top: -78%;
    }

}

/*@media screen and (aspect-ratio: 16/9){*/
    /*.webcam-slider .slick-slide > img{*/
        /*width: 870px;*/
    /*}*/
/*}*/

/*@media screen and (aspect-ratio: 8/5){*/
    /*.webcam-slider .slick-slide > img{*/
        /*width: 870px;*/
    /*}*/
/*}*/


.webcam-slider .slick-slide > img{
    width: 870px;
}

.webcam-slider-nav .slick-slide.slick-active.slick-center span > img{
width: 200px;
}

.webcam-slider-nav .slick-prev,
.webcam-slider-nav .slick-next{
height: 113px;
}

/*@media*/
/*(-webkit-min-device-pixel-ratio: 2),*/
/*(min-resolution: 192dpi) {*/
    /*.webcam-slider .slick-slide > img{*/
        /*width: 870px;*/
    /*}*/

    /*.webcam-slider-nav .slick-slide.slick-active.slick-center span > img{*/
        /*width: 200px;*/
    /*}*/

    /*.webcam-slider-nav .slick-prev,*/
    /*.webcam-slider-nav .slick-next{*/
        /*height: 113px;*/
    /*}*/
/*}*/