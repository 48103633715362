.lined-headline:before,
.headline-blog h1,
.content-block h2,
.author-teaser .teaser-content h2{
    color: #dc0014;
}

.inspiration-banner{
    background-color: #dc0014;
}

/* -------------  Navigationen ------------------- */

.info-nav-box,
.navbar-brand.info-nav-box:hover {
    background: #dc0015; /* Old browsers */
    background: -moz-linear-gradient(top,  #dc0015 0%, #aa000e 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dc0015), color-stop(100%,#aa000e)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #dc0015 0%,#aa000e 100%); /* W3C */
}

.nav-custom-items li:hover .dropdown-navigation{
    border-bottom: 1px solid #dc0014;
}

.nav-custom-items li ul > li a,
.nav-custom-items .nav-wide > ul > li > a,
.nav-custom .navbar-nav > li > a:hover,
.breadcrumb > li > li:before,
.breadcrumb > li + li:before{
    color: #dc0014;
}

.nav-box > li{
    color: #ffffff !important;
    background: #dc0014;
}

.nav-box > li.cart-empty{
    background: #bfbfbf;
    pointer-events: none;
    cursor: default;
}



.main-nav,
.webcam-caption,
#search-dialog, #language-dialog, #login-dialog, #booking-offline-dialog{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(242,243,242,1) 100%) !important;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(242,243,242,1))) !important;
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%) !important;
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%) !important;
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%) !important;
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(242,243,242,1) 100%) !important;

}

.subnav > ul > li.active > ul > li.active > ul > li.active > a,
.subnav > ul > li.active > ul > li.active > a,
.subnav > ul > li.active > a,
#js-sidebar .subnav > li.active > a,
#js-sidebar .subnav > li.active > ul > li.active > a,
.nav-custom-items .nav-wide > ul > li > a{
    color: #dc0014;
}


/* ------------- TEASER and Ribbons ---------------- */

.event-teaser .offer-teaser-ribbon,
.event-teaser-monthly .offer-teaser-ribbon,
.blog-teaser .offer-teaser-ribbon{

    background: rgb(255,51,51);
    background: -moz-linear-gradient(top,  rgba(255,51,51,1) 0%, rgba(194,0,16,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,51,51,1)), color-stop(100%,rgba(194,0,16,1)));
    background: -webkit-linear-gradient(top,  rgba(255,51,51,1) 0%,rgba(194,0,16,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,51,51,1) 0%,rgba(194,0,16,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,51,51,1) 0%,rgba(194,0,16,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,51,51,1) 0%,rgba(194,0,16,1) 100%);
}

.offer-teaser-ribbon,
.offer-teaser-ribbon-xs,
.teaser-experience-ribbon,
.restaurant-closed,
.show-restaurants i.icon.icon-locator-pin:after,
.darkpage i.icon.icon-locator-pin:after{

    background: #dc0015; /* Old browsers */
    background: -moz-linear-gradient(top,  #dc0015 0%, #aa000e 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dc0015), color-stop(100%,#aa000e)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #dc0015 0%,#aa000e 100%); /* W3C */

}

.restaurant-open{

    background: rgb(8,170,0);
    background: -moz-linear-gradient(top,  rgba(8,170,0,1) 0%, rgba(6,121,0,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(8,170,0,1)), color-stop(100%,rgba(6,121,0,1)));
    background: -webkit-linear-gradient(top,  rgba(8,170,0,1) 0%,rgba(6,121,0,1) 100%);
    background: -o-linear-gradient(top,  rgba(8,170,0,1) 0%,rgba(6,121,0,1) 100%);
    background: -ms-linear-gradient(top,  rgba(8,170,0,1) 0%,rgba(6,121,0,1) 100%);
    background: linear-gradient(to bottom,  rgba(8,170,0,1) 0%,rgba(6,121,0,1) 100%);
}

.more-live-reports,
.more-live-reports a,
.read-more a,
.read-more{
    color: #dc0014;
}

.webcam-place,
.webcam-counter{
    color: #dc0014;
}

.content-teaser h2 a,
.offer-teaser h2 a,
.event-teaser h2 a,
.event-teaser-monthly h2 a,
.ecommerce-link a,
.tipps-teaser-link a,
.tipps-read-more a{
    color: #dc0014;
}

.footer-address-bar .icon-locator-pin,
.footer-address-bar .icon-mail,
.footer-address-bar .icon-phone,
.weather-today-grad-zahl-min,
.weather-today-grad-zahl-max,
.weather-next-days-grad-zahl-min,
.weather-next-days-grad-zahl-max,
.weather-today-grad,
.avalanche-state-value,
.snow-value-text,
.footer-img-berg
{
    color: #dc0014;
}


.open-tracks-tabs ul > li.active > a:hover,
.open-tracks-tabs ul > li.active > a:link,
.event-tabs ul > li.active > a:hover,
.experiences-contact-tabs ul > li.active > a:hover{
    color: #dc0014;
}


.event-tabs ul > li,
.event-tabs ul > li > a:hover,
.experiences-contact-tabs > ul > li,
.experiences-contact-tabs > ul > li > a:hover{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(45deg,  rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(242,242,242,1)));
    background: -webkit-linear-gradient(45deg,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: linear-gradient(45deg,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
}

.buy-experience-date .form-control,
.formular-section .form-control,
.formular-section select,
.newsletter-anmeldung .form-control,
.anreise-content .form-control,
.filter-news select{
    border-color: #e6e6e6;
    border-radius: 0;
    height: 50px;

    background: rgb(255,255,255);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%, rgba(250,250,250,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%,rgba(255,255,255,1)), color-stop(100%,rgba(250,250,250,1)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);

}
