
/* TABLE */
.div-table {
	display: table;
	width: 100%;
}

.table-row {
	display: table-row;
}

.table-cell {
	width: 20%;
	height: 50px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
}

@media screen and (max-width: 767px){

    .table-cell{
        width: 25%;
    }

    .table-cell.table-cell-3{
        width: 33.3333333333%;
    }
}

.next-row-responsive{
    margin: 5px 0;
}

@media screen and (min-width: 1190px){
    .next-row-responsive{
        display: inline-block;
        margin: 0;
    }
}

#footer {
	overflow: hidden;
}
.footer-address-follow{
	background-color: #f2f2f2;
}
.footer-address-bar {
	padding-top: 50px;
	min-height: 172px;
}

.footer-address-bar a {
	text-decoration: none;
	color: #333;
    transition: color 120ms ease;
}
.footer-address-bar a:hover,
.footer-address-bar a:focus {
    color: #dc0014;
}

.footer-address-bar .icon-locator-pin,
.footer-address-bar .icon-phone{
	margin-left: -18px;
	font-size: 12px;
	padding-right: 6px;
}

.footer-address-bar .icon-phone:before{
    content: "\E01C"!important;
}

.footer-address-bar .hvr-icon-drop:before,
.footer-address-bar .hvr-icon-grow-rotate:before,
.footer-address-bar .hvr-icon-pop:before{
	font-size: 13px;
	bottom: -6px;
	left: -7px;
	font-family:'iconfont';
}

.footer-address-bar .hvr-icon-drop:before{
	left: -1px;
}

.footer-address-bar .hvr-icon-pop:before{
	font-size: 10px;
	padding-right: 5px;
}

.footer-address-bar .icon-mail,
.footer-address-bar .icon-phone,
.footer-address-bar .icon-locator-pin{
    margin-left: -18px;
    font-size: 9px;
    padding-right: 12px;
	top: -2px;
}

.footer-follow-bar {
	min-height: 172px;
	background: rgb(217, 217, 217);
	background: linear-gradient(to right, rgba(217, 217, 217, 1) 0%, rgba(242, 242, 242, 1) 100%);
}

.footer-follow-bar .col-xs-12{
	width: 130%;
}

.footer-contact-bar-mobile i{
	padding-top: 10px;
}

.footer-contact-bar-mobile .col-xs-4{
	background-color: #d9d9d9;
	color: #333;
	text-align: center;
}

.footer-address-bar-mobile {
	border: none;
	background-color: #f2f2f2;
	color: #333;
	text-align: center;

	font-family: RobotoLight, sans-serif;
	font-size: 16px;

	padding-top: 40px;
	padding-bottom: 26px;
}


.footer-follow-style-mobile {
	border-bottom: solid 1px transparent;
	margin-top: 1px;
}
.footer-follow-style-mobile .social-media{
	padding-top: 5px;
}
.footer-follow-style-mobile a{
	color: #fff;
	font-size: 18px;
}


@media screen and (max-width: 767px){
    .footer-follow-style-mobile a{
        position: relative;
        top: 12px;
    }
}

.footer-contact-bar-mobile .col-xs-12 {
	padding-left: 0;
	padding-right: 0;
}



.main-footer {
	border: none;
	background: #333333;
	padding: 35px 0;
}

.main-footer .navbar-nav > li > a {
	background-color: transparent;
	color: #bfbfbf;
	font-family: RobotoBold, sans-serif;
	font-size: 17px;
    transition: color 110ms ease;
}
.main-footer .navbar-nav>li>a:focus,
.main-footer .navbar-nav>li>a:hover {
    color: #fff;
}

.copyright {
	color: #bfbfbf;
	font-family: RobotoLight, sans-serif;
	font-size: 16px;
}

.site-by-elements {
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	text-decoration: none;
}

.footer-img-berg {
	font-size: 64px;
	position: relative;
}

.footer-follow-bar h2 {
	font-family: RobotoCondensedLight, sans-serif;
	font-size: 36px;
	color: #333;
}

.footer-follow-bar-mobile .col-xs-2 {
	padding-right: 0;
	padding-left: 0;
}

.social-squares{
	width: 50px;
	height: 50px;
	font-size: 26px;
	display: inline-block;
	margin-right: 1px;
	text-align: center;
	padding-top: 11px;
}
.social-squares a{
	color: #fff;
	text-decoration: none;
}

.social-squares-mobile {
	width: 100%;
	height: 50px;
}

.social-squares a:before{
	position: relative;
	top: -6px;
}

.social-rss {
	background-color: #dc0014;
}
.social-rss:hover {
	background-color: #c00012;
}

.social-fb {
	background-color: #3b5998;
}
.social-fb:hover {
	background-color: #35457e;
}

.social-yt {
	background-color: #c4302b;
}
.social-yt:hover {
	background-color: #a02924;
}

.social-tw {
	background-color: #00acee;
}
.social-tw:hover {
	background-color: #0095cc;
}

.social-gp {
	background-color: #dd4b39;
}
.social-gp:hover {
	background-color: #b03e2e;
}

.social-in {
    background-color: #855138;
}
.social-in:hover {
    background-color: #A46648;
}

.social-pi {
    background-color: rgb(189, 8, 28);
}
.social-pi:hover {
    background-color: rgb(189, 44, 0);
}

.main-footer .navbar-nav > li.no-padding-left > a {
	padding-left: 0;
}

@media (max-width: 991px) {
	.site-by-elements {
		padding-top: 8px;
	}

	.footer-img-berg {
		margin-left: -20px;
	}
}

@media (max-width: 768px) {
	.copyright {
		margin-top: 10px;
		font-size: 14px;
	}

	.copyright,
	.footer-links,
	.site-by-elements {
		text-align: center;
	}

	.site-by-elements {
		padding-top: 14px;
	}

	.footer-links li {
		display: inline-block;
	}

	.main-footer .navbar-nav > li > a {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

@media (max-width: 527px) {
	.footer-follow-bar,
	.footer-address-bar {
		display: none;
	}

	.footer-address-bar-mobile {
		border: none;
		background-color: #f2f2f2;
	}

	.footer-follow-bar-mobile {
		border: none;
		background-color: #fff;
	}

	.footer-address-bar-mobile .col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}

	.footer-follow-bar-mobile .col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}

}

@media (max-width: 640px) {
	.footer-img-berg {
		width: 60px;
	}

}
