

.img-lightbox,
.img-lightbox:before,
.social-squares,
.info-nav .navbar-nav > li > a:hover,
.img-lightbox:after,
.nav-live-info-mobile ul > .col-xs-6 > li:hover,
.nav-live-info-mobile ul > .col-xs-6 > li > a:hover,
.experience-teaser .offer-teaser-border:hover,
.event-teaser .offer-teaser-border:hover{
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}
.content-teaser-border {
    transition: all 110ms ease-out;
}

.content-teaser-border:hover{
	box-shadow: 0 0 5px rgba(0,0,0,.2);
}


/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	transform: translateY(0) perspective( 600px ) rotateX( 0 );
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;

	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;

	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}


/* Portal Animation */
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes flipInX {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}
	40% {
		-webkit-transform: perspective(400px) rotateX(-10deg);
	}
	70% {
		-webkit-transform: perspective(400px) rotateX(10deg);
	}
	100% {
		-webkit-transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}
@keyframes flipInX {
	0% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotateX(-10deg);
	}
	70% {
		transform: perspective(400px) rotateX(10deg);
	}
	100% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}
.flipInX {
	-webkit-backface-visibility: visible !important;
	-webkit-animation-name: flipInX;
	backface-visibility: visible !important;
	animation-name: flipInX;
}



/* Fade in Left for Header Bild */
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes label-left {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
.label-left {
	left: -15px;
	-webkit-animation-name: label-left;
	animation-name: label-left;
}



/* Fade in Right for Header Bild */
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes label-right {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
.label-right {
	float: right;
	left: 15px;
	-webkit-animation-name: label-right;
	animation-name: label-right;
}



/* Search Animation */

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
	}

	50% {
		opacity: 1;
		-webkit-transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
	}

	100% {
		-webkit-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(.3);
	}

	50% {
		opacity: 1;
		transform: scale(1.05);
	}

	70% {
		transform: scale(.9);
	}

	100% {
		transform: scale(1);
	}
}

.bounceIn {
	-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
}



/* RIPPLE */
.ripple,
[data-ripple]{
    overflow: hidden;
    position: relative;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.ripple-effect {
    height: 40px;
    position: absolute;
    width: 40px;

    -webkit-animation:  ripple 5s;
    -moz-animation:     ripple 5s;
    -ms-animation:      ripple 5s;
    -o-animation:       ripple 5s;
    animation:          ripple 5s;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-transition: background-color    600ms ease-in-out;
    -moz-transition: background-color       600ms ease-in-out;
    -ms-transition: background-color        600ms ease-in-out;
    -o-transition: background-color         600ms ease-in-out;
    transition: background-color            600ms ease-in-out;
}

@-webkit-keyframes ripple {
    from {
        -webkit-transform: scale(1);
        opacity: 0.4;
    }
    to {
        -webkit-transform: scale(100);
        opacity: 0.25;
    }
}

@-moz-keyframes ripple {
    from {
        -moz-transform: scale(1);
        opacity: 0.4;
    }
    to {
        -moz-transform: scale(100);
        opacity: 0.25;
    }
}

@-ms-keyframes ripple {
    from {
        -ms-transform: scale(1);
        opacity: 0.4;
    }
    to {
        -ms-transform: scale(100);
        opacity: 0.25;
    }
}

@-o-keyframes ripple {
    from {
        -o-transform: scale(1);
        opacity: 0.4;
    }
    to {
        -o-transform: scale(100);
        opacity: 0.25;
    }
}

@keyframes ripple {
    from {
        transform: scale(1);
        opacity: 0.4;
    }
    to {
        transform: scale(100);
        opacity: 0.25;
    }
}




.ih-item {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item,
.ih-item * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ih-item img {
  width: 100%;
  height: 100%;
}
.ih-item:hover {
  text-decoration: none;
}

.ih-item.square {
  position: relative;
  width: 316px;
  height: 216px;
  border: 8px solid #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.ih-item.square .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.ih-item.square.effect6 {
  overflow: hidden;
}
.ih-item.square.effect6.colored .info {
  background: #1a4a72;
  background: rgba(26, 74, 114, 0.6);
}
.ih-item.square.effect6.colored .info h3 {
  background: rgba(12, 34, 52, 0.6);
}
.ih-item.square.effect6 .img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.ih-item.square.effect6 .info {
  background: #333333;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.square.effect6 .info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  background: #111111;
  margin: 30px 0 0 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.square.effect6 .info p {
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #bbb;
  padding: 20px 20px 20px;
  text-align: center;
  -webkit-transition: all 0.35s 0.1s linear;
  -moz-transition: all 0.35s 0.1s linear;
  transition: all 0.35s 0.1s linear;
}
.ih-item.square.effect6:hover .img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.ih-item.square.effect6:hover .info {
  visibility: visible;
  opacity: 1;
}


.ih-item.square.effect6.from_top_and_bottom .info h3 {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.ih-item.square.effect6.from_top_and_bottom .info p {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.ih-item.square.effect6.from_top_and_bottom:hover .info h3,
.ih-item.square.effect6.from_top_and_bottom:hover .info p {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
