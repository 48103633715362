.snow-value-snow .icon-nav-snow {
    width: auto;
}
/*
.avalanche-square{
    top: -10px;
}

.avalanche-square .number{
    top: -5px;
    left: 3px;
}
*/
.footer-follow-bar {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9d9d9', endColorstr='#f2f2f2');
}
