
.blog-teaser .offer-teaser-border{
    overflow: visible;

    background: rgb(255,255,255);
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(242,242,242,1)));
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -o-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: -ms-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=1 );

}

.blog-teaser .offer-teaser-ribbon {
    height: 70px;
    width: 70px;
    text-align: center;
    margin-top: 38px;
    margin-left: -5px;
    -webkit-backface-visibility: hidden;
    transform: skewX(0deg);
}

.blog-teaser .offer-teaser-ribbon.ribbon-recent-entry{
    margin-top: 12px;
}

.blog-meta span,
.author-meta span{
    color: #004678;
    margin-right: 15px;
}

.blog-teaser.recent-entry .teaser-img{
    padding: 0;
}

.blog-teaser.recent-entry .teaser-content{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.recent-entry-img.col-xs-12{
    padding-left: 11px;
    padding-right: 11px;
    top: -4px;
}

.blog-paging{
    text-align: center;
}

.blog-teaser .teaser-content{
   margin-top: 30px;
}

.headline-blog h1{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
}

.author-teaser .offer-teaser-border{
    border: none;
    margin-bottom: 0;
}

.author-teaser .offer-teaser-border:hover{
    box-shadow: none;
    cursor: default;
}

.author-teaser .teaser-img{
    padding: 0;
}

.author-teaser{
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    padding-top: 30px;
    padding-bottom: 30px;
}

.author-teaser .teaser-content h2{
    font-family: RobotoBold, sans-serif;
    font-size: 24px;
}

.blog-header-box{
    margin-left: 255px;
    position: absolute;
}

.blog-teaser a{
    text-decoration: none;
}


/* -------  BLOG SIDEBAR  ------- */

#js-sidebar .sidebar-headline,
.js-sidebar .sidebar-headline{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 20px;
    color: #dc0014;
}

#js-sidebar .dropdown-wrapper.small-dropdown{
    margin-top: 25px;
}


#js-sidebar .dropdown.active div a {
    display: block;
}

#js-sidebar a {
    display: block;
    font-family: SourceSansPro-Light,sans-serif;
    font-size: 17px;
    color: #333;
    padding: 3px 0;
    position: relative;
}

#js-sidebar>ul>li>div a:before {
    content: "\E002";
    font-family: iconfont;
    position: absolute;
    left: -10px;
    top: 9px;
    font-size: 8px;
}

.dropdown-wrapper small {
    padding-top: 2px;
    float: right;
}

#js-sidebar .sidebar-headline:after {
    background: #d9d9d9;
    height: 1px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
}

.js-sidebar.sidebar-best{
    margin-top: 40px;
}

.js-sidebar .blog-best h4 {
    font-size: 16px;
    margin: 0 0 4px 0;
}

.js-sidebar .blog-best a {
    color: #dc0014;
    display: block;
}

.js-sidebar .blog-best a:hover,
#js-sidebar .dropdown.active div a:hover,
.sidebar-archive .list-unstyled > li > a:hover{
    color: #0064aa;
    text-decoration: none;
}

.js-sidebar .dropdown-wrapper.small-dropdown{
    margin-top: 10px;
}

.js-sidebar .sidebar-headline:after {
    background: #d9d9d9;
    height: 1px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 32px;
}

.js-sidebar .blog-best{
    margin-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.js-sidebar.sidebar-archive{
    margin-top: 40px;
}

.blog-best .blog-meta i {
    margin-right: 5px;
    font-size: 12px;
}

.blog-best .blog-meta span{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    margin-right: 12px;
}

.blog-best .col-md-3.col-sm-12{
    padding-right: 0;
    padding-left: 0;
}

.sidebar-archive .list-unstyled .archive-year small{
    color: #333;
    position: relative;
    left: 11px;
}

.sidebar-archive .list-unstyled > li > a{
    color: #333;
    position: relative;
    display: block;
    left: 5px;
}

.sidebar-archive .list-unstyled > li.active > a,
.sidebar-archive .list-unstyled > li.active .archive-year small{
    color: #0064aa;
}

.sidebar-archive .list-unstyled > li > a:before{
    content: "\E002";
    font-family: iconfont;
    position: absolute;
    left: -15px;
    top: 6px;
    font-size: 9px;
}

.sidebar-archive .list-unstyled > li.active > a:before{
    content: "\E002";
    font-family: iconfont;
    transform: rotate(90deg);
    position: absolute;
    left: -15px;
    top: 6px;
    font-size: 9px;
}

.sidebar-archive .list-unstyled > li > ul > li > a{
    display: block;
    position: relative;
    color: #333;
    left: 16px;
}

.sidebar-archive .list-unstyled > li > ul > li > a:before{
    content: "\E002";
    font-family: iconfont;
    transform: rotate(0deg);
    position: absolute;
    left: -12px;
    top: 6px;
    font-size: 9px;
}

.sidebar-archive .list-unstyled{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

.blog-search .form-group input{
    height: 50px;
}

.blog-search input{
    border-color: #e6e6e6;
    border-radius: 0;
    background: #fff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  #fff 0%, #ffffff 0%, #ffffff 32%, #f5f5f5 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#fff), color-stop(0%,#ffffff), color-stop(32%,#ffffff), color-stop(100%,#f5f5f5)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover,  #fff 0%,#ffffff 0%,#ffffff 32%,#f5f5f5 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  #fff 0%,#ffffff 0%,#ffffff 32%,#f5f5f5 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  #fff 0%,#ffffff 0%,#ffffff 32%,#f5f5f5 100%); /* IE10+ */
    background: radial-gradient(ellipse at center,  #fff 0%,#ffffff 0%,#ffffff 32%,#f5f5f5 100%); /* W3C */

}

.btn-blog-search{
    background-color: #dc0014;
    color: #fff;
    border: none;
    height: 50px;
    width: 50px;
    font-size: 21px;
    position: absolute;
    top: 0;
    right: 0;
}

.btn-blog-search i{
    position: relative;
    top: 2px;
}

.btn-blog-search:focus{
    outline: none;
}

@media screen and (min-width: 768px) {
    #blog .teaser-day{
        margin-top: 0;
        line-height: 32px;
    }
    #blog .teaser-month{
        margin-top: 7px;
    }
}

.sm-text{
    display: block;
    height: 24px;
}
.sm-text span{
    margin-top: -2px;
}
.sm-share{
    display: inline-block;
    margin-right: 10px;
    height: 24px;
    overflow: hidden;
}

.twitter-share,
.google-share{
	padding-top: 2px;
	display: inline-block;
}


/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 0 - 991px ----------------------- */
@media screen and (max-width: 991px) {
    .blog-best .col-md-3.col-sm-12{
        padding-right: 15px;
        padding-left: 15px;
    }
}

/* ----------------------- 0 - 768px ----------------------- */
@media screen and (max-width: 768px) {
    .blog-teaser .offer-teaser-ribbon{
        margin-left: -24px;
    }

    .blog-detail .blog-meta{
        margin-top: 20px;
    }

    .blog-teaser .teaser-content,
    .blog-teaser.recent-entry .teaser-content{
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    .teaser-day {
        margin-top: -5px;
    }
    .teaser-month {
        margin-top: 7px;
    }

    .teaser-content span.meta{
        font-size: 13px;
    }
    .teaser-content .blog-category{
        display: block;
    }

}

/* ----------------------- 0 - 360px ----------------------- */
@media screen and (max-width: 360px) {

    .blog-teaser .offer-teaser-ribbon{
        height: 50px;
        width: 50px;
        margin-top: 17px;
    }

    .teaser-month {
        font-size: 14px;
        margin-top: 1px;
    }

    .teaser-day {
        font-size: 24px;
        margin-top: -4px;
    }
}
